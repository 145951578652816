import { axiosInstance } from '@/utils/axios'
import snakecaseKeys from 'snakecase-keys'
import { downloadBlobFileHandler } from '@/modules/fileHandlers'
import { getFilenameFromContentDisposition } from '@/modules/utilFns'

const getReport = (formSetId) => {
    const data = snakecaseKeys({formSet: formSetId}, {deep: true})
    const retObj = {type: 'success', message: 'successfully downloaded report'}
    return axiosInstance.post(
        '/api/reports/pdf/summary/', {...data}, {responseType: 'arraybuffer'}
    ).then((response) => {
        console.log('response from endpoint', response)
        const filename = getFilenameFromContentDisposition(response) || 'report.pdf'
        downloadBlobFileHandler(response.data, filename)
    }).catch((error) => {
        retObj.type = 'error'
        retObj.message = 'Failed to download document'
    }).finally(() => {
        return retObj
    })
}

export {
    getReport
}
