<template lang="pug">
    .rounded.px-4.py-3.box-bg.mb-4
        .flex.items-center.justify-between.border-b-2.border-primary.pb-3
            .flex.items-center
                h1 Surveys Completed
            
        div(class="mt-2 grid lg:grid-cols-1 xl:grid-cols-5 gap-4")
            div(class="col-span-2")
                v-data-table(
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    class=""
                )
            div(class="col-span-3")
                div(v-if="!chartLoading")
                    .mt-4
                        MixedChart(
                            title="Surveys Completed (average over time period)"
                            :chartData='chartData'
                            :scales="scales"
                            :height="340"
                            :label="getTooltipText"
                            :legend="chartLegend"
                        )
                Loader(
                    v-else
                    class="w-100"
                )
</template>

<script>
import MixedChart from '@/components/charts/MixedChart'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery } from '@vue/apollo-composable'
import {
    GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE, GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT
} from '@/graphql/queries/formStatQueries.gql'
import Filters from '@/components/dashboard/partials/Filters'
import {
    getGraphDataByDates,
    getYAxisLabelWithoutDecimal,
    formatResultsForRacgpTotalSurveys, getPointRadius
} from '@/modules/surveyStats'
import { getDateLabels } from '@/modules/dateHelpers'
import Loader from '@/assets/loader.svg'
import { startOfDay, endOfDay, format } from 'date-fns'
import camelcaseKeys from 'camelcase-keys'
import { CHART_OPTION_COLORS } from '@/common/constants'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'SurveysCompleted',
    components: { MixedChart, Filters, Loader },
    setup() {
        const { tenantId, dashboardFilter } = useGetters(['tenantId', 'dashboardFilter'])
        const params = computed(() => {
            return {
                tenantIdCompare: { _eq: tenantId.value },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            }
        })
        const peerParams = computed(() => {
            return {
                tenantIdCompare: { _neq: tenantId.value },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            }
        })
        const metricRange = computed(() => {
            if (!dashboardFilter.value?.dateRange?.startDate) return ''
            return `${format(new Date(dashboardFilter.value?.dateRange?.startDate), 'dd/MM/yyyy')}~${format(new Date(dashboardFilter.value?.dateRange?.endDate), 'dd/MM/yyyy')}`
        })
        const dateFormat = ref('MMM dd, yyyy')
        // const peersCountForShortSurvey = ref(0)
        // const peersCountForLongSurvey = ref(0)
        const peersCountForSurvey = ref('0')
        const myClinicsCountForSurvey = ref(0)
        // const myClinicsCountForShortSurvey = ref(0)
        // const myClinicsCountForLongSurvey = ref(0)

        const chartData = ref({
            labels: [],
            datasets: [
                {
                    label: 'Peer Surveys Completed (average over time period)',
                    data: [],
                    borderColor: '#F57C00',
                    backgroundColor: '#F57C00',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    yAxisID: 'peer_surveys_completed',
                    order: 1
                }, {
                    label: 'Surveys Completed (average over time period)',
                    data: [],
                    borderColor: '#4EBBFF',
                    backgroundColor: '#4EBBFF',
                    stack: 'combined',
                    barThickness : 43,
                    yAxisID: 'surveys_completed',
                    order: 2
                }
            ]
        })
        const scales = {
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display: false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
            }],
            yAxes: [{
                id: 'average_survey_score',
                scaleLabel: {
                    display: true,
                    labelString: 'Average Survey Score',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    min: 0,
                    max: 5,
                    callback: getYAxisLabelWithoutDecimal
                }
            }, {
                id: 'surveys_completed',
                scaleLabel: {
                    display: true,
                    labelString: 'Surveys Completed',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor,
                    drawOnChartArea: false
                },
                position: 'right',
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            }]
        }

        const chartLegend = {
            position: 'bottom',
            labels: {
                boxWidth: 12,
                fontColor: CHART_OPTION_COLORS.axisLabelColor,
                font: {
                    family: 'Open Sans, sans-serif',
                    size: 6
                }
            },
            reverse: true
        }

        const headers = ref([
            { text: '', align: 'left', value: 'name', class: '' },
            { text: 'My Clinic', align: 'left', value: 'clinic', class: '' },
            { text: 'Peers', align: 'left', value: 'peers', class: '' }
        ])
        const items = computed(() => {
            const metricText = metricRange.value ? `(${metricRange.value})` : ''
            return [
                { name: `Surveys completed ${metricText}`, clinic: myClinicsCountForSurvey.value, peers: peersCountForSurvey.value},
                // { name: `Short Surveys completed ${metricText}`, clinic: myClinicsCountForShortSurvey.value, peers: peersCountForShortSurvey.value},
                // { name: `Long Surveys completed ${metricText}`, clinic: myClinicsCountForLongSurvey.value, peers: peersCountForLongSurvey.value },

                // { name: 'Short Surveys completed per FTE (past 30 days)', clinic: 44, peers: 72 },
                // { name: 'Long Surveys completed per FTE (past 30 days)', clinic: 21, peers: 39 }
            ]
        })

        const {
            onResult: getStatsOnResult,
            onError: getStatsOnError,
            refetch: getStatsRefetch,
            load: getStatsLoad,
            loading: scoreChartLoading
        } = useLazyQuery(GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE, params)

        const {
            onResult: getClinicTotalSurveysOnResult,
            onError: getClinicTotalSurveysOnError,
            refetch: getClinicTotalSurveysRefetch,
            load: getClinicTotalSurveysLoad,
            loading: getClinicTotalSurveysLoading
        } = useLazyQuery(GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT, params)

        getClinicTotalSurveysOnResult(({ data }) => {
            if (getClinicTotalSurveysLoading.value) return
            const totalSurveys = camelcaseKeys(data?.totalSurveys, {deep: true})
            myClinicsCountForSurvey.value = totalSurveys?.reduce((acc, curr) => {
                acc += Number(curr.surveyCount || 0)
                return acc
            }, 0)
        })

        getStatsOnResult(({ data }) => {
            if (scoreChartLoading.value) return

            // console.log('===== my clinic scores', data.scores)
            const surveys = formatResultsForRacgpTotalSurveys(data, dateFormat.value)
            if (!surveys) return

            const tempChartData = { ...chartData.value }
            tempChartData.datasets[1].data = getGraphDataByDates(chartData.value.labels, surveys)
            chartData.value = { ...tempChartData }
        })

        const {
            onResult: getPeersTotalSurveysOnResult,
            onError: getPeersTotalSurveysOnError,
            refetch: getPeersTotalSurveysRefetch,
            load: getPeersTotalSurveysLoad,
            loading: getPeersTotalSurveysLoading
        } = useLazyQuery(GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT, peerParams)

        getPeersTotalSurveysOnResult(({ data }) => {
            if (getClinicTotalSurveysLoading.value) return
            const totalSurveys = camelcaseKeys(data?.totalSurveys, {deep: true})
            peersCountForSurvey.value = totalSurveys?.reduce((acc, curr) => {
                acc += Number(curr.surveyCount || 0)
                return acc
            }, 0)
        })

        const {
            onResult: getPeerStatsOnResult,
            onError: getPeerStatsOnError,
            refetch: getPeerStatsRefetch,
            load: getPeerStatsLoad,
            loading: peerStatsLoading
        } = useLazyQuery(GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE, peerParams)

        getPeerStatsOnResult(({data}) => {
            if (!data?.scores) return

            // const scores = camelcaseKeys(data.scores, {deep: true})
            const peerSurveys = formatResultsForRacgpTotalSurveys(data, dateFormat.value, true)
            if (!peerSurveys) return

            // console.log('===== peer scores', peerSurveys)
            const tempChartData = { ...chartData.value }
            const generatedData = getGraphDataByDates(chartData.value.labels, peerSurveys)
            const mappedData = generatedData.map((g) => Number(g || 0))
            // console.log('====== generated data', generatedData, mappedData)
            tempChartData.datasets[0].data = [...(mappedData || [])]
            // chartData.value = { ...tempChartData }
        })

        watch(() => dashboardFilter.value, (nextValue, prevValue) => {
            const { startDate, endDate } = nextValue.dateRange
            const { labels, format } = getDateLabels(startDate, endDate)

            chartData.value.labels = labels
            dateFormat.value = format
            // chartData.value = {...chartData.value}

            if (nextValue !== prevValue) {
                getStatsRefetch()
                getPeerStatsRefetch()
                getClinicTotalSurveysRefetch()
                getPeersTotalSurveysRefetch()
            }
        }, { deep: true })

        const chartLoading = computed(() => {
            return peerStatsLoading.value && scoreChartLoading.value
        })

        const getTooltipText = (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value} (${tooltipItem.label})`
        }

        onMounted(() => {
            if (!dashboardFilter.value?.dateRange) return

            const { dateRange: { startDate, endDate } } = dashboardFilter.value
            if (!startDate || !endDate) return

            const chart = {...chartData.value}
            const { labels, format } = getDateLabels(startDate, endDate)
            chart.labels = labels
            dateFormat.value = format
            chartData.value = {...chart}
        })

        getStatsLoad()
        getPeerStatsLoad()
        getPeersTotalSurveysLoad()
        getClinicTotalSurveysLoad()

        return {
            chartData,
            scales,
            headers,
            items,
            chartLoading,
            metricRange,
            getTooltipText,
            chartLegend
        }
    }
}
</script>
