<template lang="pug">
    div(class="flex items-center select-wrapper" v-if="checkHasData")
        v-select(
            v-model="filter.formSetId"
            :items="items"
            text="text"
            item-text="text"
            item-value="id"
            item-disabled="itemDisabled"
            hide-details
            dense
            class="fit c-form-set-list__autocomplete-text-2xl"
        )
            template(v-slot:item="{ item, on, attrs, parent }")
                v-list-item(
                    :key="`form-set-select-${item.id}`"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="isDisabled(item.formType) && !isFreeTrial"
                    :value="item.value"
                    class="dashboard-dropdown flex items-center"
                )
                    div(class="text-primary" :class="{'text-grey-2 opacity-75': isDisabled(item.formType) && !isFreeTrial}")
                        | {{ item.text }}
                    fa-icon(v-if="isDisabled(item.formType) && !isFreeTrial" icon="lock" class="ml-2 text-grey-2 opacity-75")
                    div(v-if="isDisabled(item.formType)" class="uppercase text-xs text-white bg-green py-1 px-2 rounded ml-2 font-bold")
                        | UPGRADE PLAN
        date-picker(
            ref="datePicker"
            v-model="dateRange"
            :prefixClass="'xmx'"
            range
            :clearable="false"
            :shortcuts="shortcuts"
            placeholder="Select date range"
            class="ml-4 mt-3 bg-navy-light rounded"
            @change="dateRangeChanged"
        )
            template(v-slot:icon-calendar)
                fa-icon(icon="calendar-alt" class="text-grey-2")
            template(v-slot:input)
                v-text-field(
                    v-model="formattedRangeText"
                    filled
                    hide-details
                    dense
                )
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import camelcaseKeys from 'camelcase-keys'
import DatePicker from 'vue2-datepicker'
import { format, subDays } from 'date-fns'
import { storeToRefs } from 'pinia'
import { GET_FORMSETS_FOR_TENANT } from '@/graphql/queries/getFormsForTenant.gql'
import { useLazyQuery, useQuery } from '@vue/apollo-composable'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { logErrorMessages } from '@vue/apollo-util'
import { CLINIC_SUMMARY_FILTER_DATE_OPTIONS } from '@/common/constants'
import { useTenantSubscription } from '@/composables/useTenantSubscription'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'
import { useFormHelpers } from '@/composables/useFormHelpers'

const { useGetters, useActions } = createNamespacedHelpers('')
const logError = (error) => {
    console.log('error that happened')
    logErrorMessages(error)
}

export default {
    name: 'FormSetList',
    components: { DatePicker },
    props: {
        initialFormSetId: {
            type: [String, null],
            default: null
        }
    },
    emits: ['form-set-list-loading'],
    setup(props, { emit }) {
        const items = ref([])
        const dateOptions = ref(CLINIC_SUMMARY_FILTER_DATE_OPTIONS)
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantId, parentTenantId } = storeToRefs(tenantDetailsStore)
        const { isLite, hasAtLeastLiteOption, isPremium, isFreeTrial, subscriptionHaveLoaded } = useTenantSubscription()
        const { isNps } = useFormHelpers()
        const { setDashboardFilter, setFormSet } = useActions(['setDashboardFilter', 'setFormSet'])
        const initialFormSetId = computed(() => props.initialFormSetId)
        const formSetsLoaded = ref(false)
        // const { tenantId, parentTenantId } = useGetters(['tenantId', 'parentTenantId'])

        const dateRange = ref([
            subDays(new Date(), 30),
            new Date()
        ])

        const formattedRangeText = computed(() => {
            const displayStartDate = format(dateRange.value[0], 'dd/MM/yyyy')
            const displayEndDate = format(dateRange.value[1], 'dd/MM/yyyy')

            if (displayStartDate === displayEndDate) return displayStartDate
            else return `${displayStartDate} ~ ${displayEndDate}`
        })

        const filter = ref({
            dateRange: {
                startDate: format(dateRange.value[0], 'yyyy-MM-dd'),
                endDate: format(dateRange.value[1], 'yyyy-MM-dd')
            },
            formSetId: ''
        })

        //TODO: do we need to have the current tenantId here? maybe just the parentTenantId? or maybe we don't show this page if there is no parentTenantId?
        const tenantIdCompare = ref({
            tenantId: tenantId.value,
            tenantIdCompare: [{
                tenant_id: { _eq: tenantId.value }
            }]
        })
        if (parentTenantId.value) {
            tenantIdCompare.value.tenantIdCompare = [
                ...tenantIdCompare.value.tenantIdCompare,
                { tenant_id: { _eq: parentTenantId.value } }
            ]
        }

        const {
            onResult,
            onError,
            refetch: getFormsRefetch,
            load: getFormsLoad,
            loading: getFormsLoading
        } = useLazyQuery(GET_FORMSETS_FOR_TENANT, tenantIdCompare)

        emit('form-set-list-loading', getFormsLoading.value)
        onResult(({ data }) => {
            let formSets = camelcaseKeys(data?.formSets || [], { deep: true })
            // first we go through and get only the first of each 'formSetCode', if it's null we just take all of them
            formSets = formSets.reduce((acc, formSet) => {
                if (!formSet.formSetCode || !acc.some((fs) => fs.formSetCode === formSet.formSetCode)) {
                    acc.push(formSet)
                }
                return acc
            }, [])
            items.value = formSets.map(fs => {
                return {
                    id: fs.formSetId,
                    text: fs.name,
                    formType: fs.formType
                }
            })
            if (items.value.length) {
                // first we check if we have a saved formSetId from the previous screen
                if (initialFormSetId.value) filter.value.formSetId = initialFormSetId.value
                else filter.value.formSetId = items.value[0].id
            }
            emit('form-set-list-loading', getFormsLoading.value)
        })

        onError(logError)

        const shortcuts = ref([
            { text: 'today', onClick: () => [new Date(), new Date()] },
            {
                text: 'Yesterday',
                onClick: () => {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    return [date, date];
                },
            },
        ])

        for (let dateOption of CLINIC_SUMMARY_FILTER_DATE_OPTIONS) {
            shortcuts.value.push({
                text: dateOption.text,
                onClick: () => {
                    const start = subDays(new Date(), dateOption.offset)
                    const end = new Date()
                    filter.value.dateRange.startDate = format(start, 'yyyy-MM-dd')
                    filter.value.dateRange.endDate = format(end, 'yyyy-MM-dd')
                    return [start, end]
                }
            })
        }

        const dateRangeChanged = () => {
            if (!dateRange.value[0] || !dateRange.value[1]) return
            const startDate = format(dateRange.value[0], 'yyyy-MM-dd')
            const endDate = format(dateRange.value[1], 'yyyy-MM-dd')

            filter.value.dateRange.startDate = startDate
            filter.value.dateRange.endDate = endDate
            setDashboardFilter({ ...filter.value })
        }

        const isDisabled = (formType) => {
            if (hasAtLeastLiteOption.value) {
                if (isLite.value && isNps(formType)) return false
                else if (isPremium.value) return false
            }
            return true
        }

        watch(() => filter.value.formSetId, (newValue) => {
            // if (newValue !== prevValue) setDashboardFilter(filter.value)
            setDashboardFilter({ ...filter.value })
            // and we set the formset in the vuex store - should be pinia
            const foundFormSet = items.value?.find(fs => fs.id === newValue)
            setFormSet(foundFormSet)
        })

        watch([tenantId, parentTenantId], (newValue) => {
            const [newTenantId, newParentId] = newValue
            if (!newTenantId || !newParentId) return
            tenantIdCompare.value.tenantId = newTenantId
            // if (newValue) {
            tenantIdCompare.value.tenantIdCompare = [{
                tenant_id: { _eq: newTenantId }
            }, {
                tenant_id: { _eq: newParentId }
            }]
            if (!formSetsLoaded.value) {
                getFormsLoad()
                formSetsLoaded.value = true
            } else {
                getFormsRefetch(tenantIdCompare.value)
            }
            // }
        }, { immediate: true })

        const checkHasData = computed(() => {
            return !!items.value.length
        })


        return {
            formattedRangeText,
            items,
            dateRange,
            dateOptions,
            filter,
            shortcuts,
            checkHasData,
            isFreeTrial,
            dateRangeChanged,
            isDisabled
        }
    }
}
</script>
