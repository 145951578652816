<template lang="pug">
    .rounded.px-4.py-3.box-bg.mb-4
        .flex.items-center.justify-between.border-b-2.border-primary.pb-3
            .flex.items-center
                h1.mr-2 Select a Topic
                v-select(
                    outlined dense
                    v-model="topic"
                    :items="categories"
                    item-text="name"
                    item-value="name"
                    :attach="true"
                    hide-details
                    class="ml-4 w-200"
                )
        div(v-if="!scoreChartLoading" class="mt-2 grid lg:grid-cols-1 xl:grid-cols-3 gap-4")
            div(class="col-span-2")
                .mt-4
                    MixedChart(
                        title="Average Topic Scores"
                        :chartData='chartData1'
                        :scales="scales1"
                        :height="340"
                        :label="getTooltipText"
                        :legend="chart1Legend"
                    )
            div(class="col-span-1")
                .mt-4
                    MixedChart(
                        title="Topic Score Count"
                        :chartData='chartData2'
                        :scales="scales2"
                        :height="340"
                        :label="getTooltipText"
                        :legend="scoreChartLegend"
                    )
        div(v-else class="mt-2 grid grid-cols-2 gap-4")
            Loader(
                class="col-span-2 w-100"
            )
            div(class="col-span-2 text-center")
                | Loading charts, please wait...
</template>

<script>
import MixedChart from '@/components/charts/MixedChart'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { useLazyQuery } from '@vue/apollo-composable'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Filters from '@/components/dashboard/partials/Filters'
import { getDateLabels } from '@/modules/dateHelpers'
import { GET_CATEGORIES_BY_FORM_SET_ID } from '@/graphql/queries/getCategories.gql'
import camelcaseKeys from 'camelcase-keys'
import {
    getPointRadius,
    getYAxisLabelWithoutDecimal,
    getYAxisLabel,
    getAverageGraphDataByDatesRefactored
} from '@/modules/surveyStats'
import { SURVEY_STATS_SCORE_COLOURS, CHART_OPTION_COLORS } from '@/common/constants'
import { useFormSetStore } from '@/stores/useFormSetStore'
import { storeToRefs } from 'pinia'
import Loader from '@/assets/loader.svg'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'CategoryStatistics',
    components: { MixedChart, Filters, Loader },
    setup() {
        const formSetStore = useFormSetStore()
        const { practiceSurveyByCategoryStats, peerSurveyByCategoryStats } = storeToRefs(formSetStore)
        const { updateCategory } = formSetStore
        const { tenantId, dashboardFilter, parentTenantId } = useGetters(['tenantId', 'dashboardFilter', 'parentTenantId'])
        const categories = ref([])
        const categoryIds = ref({})
        const dateFormat = ref('MMM dd, yyyy')
        const topics = ref([])
        const topic = ref('')
        const scoreChartLoading = ref(false)

        const chartData1 = ref({
            labels: [],
            datasets: [
                {
                    label: 'Average Peer Score',
                    data: [],
                    borderColor: '#F57C00',
                    backgroundColor: '#F57C00',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data)
                    },
                    yAxisID: 'average_survey_score'
                }, {
                    label: 'Average Survey Score',
                    data: [],
                    borderColor: '#4EBBFF',
                    backgroundColor: '#4EBBFF',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    yAxisID: 'average_survey_score'
                }
            ]
        })
        const chartData2 = ref({
            labels: ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent' ],
            datasets: [
                {
                    label: 'Average Peer Count',
                    data: [],
                    borderColor: '#932ad3',
                    backgroundColor: '#932ad3',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                },
                {
                    label: 'Average Score Count',
                    data: [],
                    backgroundColor: SURVEY_STATS_SCORE_COLOURS,
                    stack: 'combined',
                    barThickness : 43
                }
            ]
        })
        const scales1 = {
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display: false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
            }],
            yAxes: [{
                id: 'average_survey_score',
                scaleLabel: {
                    display: true,
                    labelString: 'Average Survey Score',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    stepSize: 0.5,
                    min: 0,
                    max: 5,
                    callback: getYAxisLabelWithoutDecimal
                }
            }, {
                id: 'surveys_completed',
                scaleLabel: {
                    display: true,
                    labelString: 'Surveys Completed',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor,
                    drawOnChartArea: false
                },
                position: 'right',
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            }]
        }
        const scales2 = {
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display:false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Topic Count',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: false,
                    callback: getYAxisLabel
                }
            }]
        }

        const chart1Legend = {
            position: 'bottom',
            labels: {
                boxWidth: 12,
                fontColor: CHART_OPTION_COLORS.axisLabelColor,
                font: {
                    family: 'Open Sans, sans-serif',
                    size: 6
                }
            },
            reverse: true
        }
        const scoreChartLegend = {
            position: 'bottom',
            labels: {
                boxWidth: 12,
                fontColor: CHART_OPTION_COLORS.axisLabelColor,
                font: {
                    family: 'Open Sans, sans-serif',
                    size: 6
                },
                filter: (item, chart) => {
                    return item.text !== 'Average Score Count';
                }
            }
        }

        const categoryParams = computed(() => {
            return {
                tenantId,
                formSetId: dashboardFilter.value.formSetId,
                parentTenantId
            }
        })

        const mixedChartTitleText = computed(() => {
            return `Average Survey Scores${topic.value ? ` for ${topic.value}` : ''}`
        })

        const {
            onResult: categoriesResult,
            onError: categoriesOnError,
            refetch: categoriesRefetch,
            load: categoriesLoad,
            loading: categoriesLoading
        } = useLazyQuery(GET_CATEGORIES_BY_FORM_SET_ID, categoryParams)

        categoriesResult(({ data }) => {
            if (!data?.categories) {
                categories.value = []
                return
            }

            categories.value = camelcaseKeys(data.categories, {deep: true}).map(category => {
                categoryIds.value[category.name] = category.categoryId
                return { name: category.name, id: category.categoryId }
            })
            topic.value = categories.value[0].name
        })

        watch(topic, (nextValue, prevValue) => {
            if(nextValue !== prevValue) updateCategory(nextValue)
        }, { immediate: true })

        watch(practiceSurveyByCategoryStats, ({ data }) => {
            if (!data?.scores?.length) return

            const { scores } = data
            const newData = getAverageGraphDataByDatesRefactored(chartData1.value.labels, scores, dateFormat.value)
            chartData1.value.datasets[1].data = [...newData]
            chartData1.value = {...chartData1.value}

            let avgCount = [0, 0, 0, 0, 0] // [Poor, Fair, Good, Very Good, Excellent]
            scores.map(({ score }) => {
                if (score >= 4) avgCount[3] += 1
                else if (score >= 3) avgCount[2] += 1
                else if (score >= 2) avgCount[1] += 1
                else avgCount[0] += 1
            })
            chartData2.value.datasets[1].data = avgCount
            chartData2.value = {...chartData2.value}
        }, { deep: true, immediate: true })

        watch(peerSurveyByCategoryStats, ({ data }) => {
            if (!data?.scores) return

            scoreChartLoading.value = true
            const { scores } = data
            chartData1.value.datasets[0].data = getAverageGraphDataByDatesRefactored(chartData1.value.labels, scores, dateFormat.value)
            chartData1.value = {...chartData1.value}

            let avgCount = [0, 0, 0, 0, 0] // [Poor, Fair, Good, Very Good, Excellent]
            scores.map(({ score }) => {
                if (score >= 4) avgCount[3] += 1
                else if (score >= 3) avgCount[2] += 1
                else if (score >= 2) avgCount[1] += 1
                else avgCount[0] += 1
            })

            chartData2.value.datasets[0].data = avgCount
            chartData2.value = {...chartData2.value}
            setTimeout(() => {
                scoreChartLoading.value = false
            }, 1000)
        }, { deep: true, immediate: true })

        watch(() => dashboardFilter.value, (nextValue, prevValue) => {
            const chart = {...chartData1.value}
            const { startDate, endDate } = nextValue.dateRange
            const { labels, format } = getDateLabels(startDate, endDate)
            categoriesRefetch()

            chart.labels = labels
            dateFormat.value = format
            chartData1.value = {...chart}
        }, { deep: true, immediate: true })

        const getTooltipText = (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value} (${tooltipItem.label})`
        }

        onMounted(() => {
            if (!dashboardFilter.value?.dateRange) return

            const { dateRange: { startDate, endDate } } = dashboardFilter.value
            if (!startDate || !endDate) return

            const chart = {...chartData1.value}
            const { labels, format } = getDateLabels(startDate, endDate)
            chart.labels = labels
            dateFormat.value = format
            chartData1.value = {...chart}

            categoriesLoad()
        })

        return {
            chartData1,
            chartData2,
            scales1,
            scales2,
            topic,
            topics,
            categories,
            mixedChartTitleText,
            getTooltipText,
            scoreChartLegend,
            chart1Legend,
            scoreChartLoading
        }
    }
}
</script>
