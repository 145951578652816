<template lang="pug">
    div
        div(class="my-4 grid grid-cols-12 gap-4 items-center")
            div(class="col-span-12 xl:col-span-4 h-24")
                .rounded.p-4.flex.items-center.box-bg.h-full
                    SurveySvg(class="w-12 mr-4 fill-current text-primary")
                    h1(class="text-lg lg:text-xl text-white mr-8")
                        | {{ totalSurveys }}
                        div(class="text-xs text-grey-2") Total Surveys Completed

        ClinicSummary
        TopicSummary
        CategoryStatistics
        SurveysCompleted
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import MixedChart from '@/components/charts/MixedChart'
import Loader from '@/assets/loader.svg'
import SurveySvg from '@/assets/icons/check-list.svg'
import ClinicSummary from '@/components/dashboard/ClinicSummary'
import TopicSummary from '@/components/dashboard/TopicSummary'
import CategoryStatistics from '@/components/dashboard/CategoryStatistics'
import SurveysCompleted from '@/components/dashboard/SurveysCompleted'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'RACGPMockup',
    components: { MixedChart, Loader, SurveySvg, TopicSummary, CategoryStatistics, SurveysCompleted, ClinicSummary },
    props: {
        totalSurveys: {
            type: [Number, null],
            default: 0
        }
    },
}
</script>
