<template lang="pug">
    div(class="my-4 grid grid-cols-12 gap-4 items-center")
        div(class="col-span-12 xl:col-span-12 h-24")
            .rounded.p-4.flex.items-center.box-bg.h-full
                SurveySvg(class="w-12 mr-4 fill-current text-primary")
                div(class="flex flex-col mr-8 lg:mr-auto")
                    h1(class="text-lg lg:text-xl text-white")
                        | {{ npsScoreComputed }}
                    div.flex.items-center
                        div(class="text-xs text-grey-2 font-bold") Clinic PSS
                        v-menu(
                            :close-on-content-click="false"
                            offset-y
                            open-on-hover
                            class="align-middle"
                        )
                            template(v-slot:activator="{ on, attrs }")
                                div(v-bind="attrs" v-on="on" class="p-1") 
                                    fa-icon(icon="question-circle" class="m-0 align-baseline text-grey-2 text-xs h-3")
                            div(class="tool-tip-vmenu")
                                div
                                    div.text-sm
                                        b Clinic PSS
                                        | is your clinic's patient satisfaction score (PSS). To read more about this metric click
                                        a(href="https://ultimateq.health/user-guide" class="text-primary text-sm inline" target="_blank") here.

                h1(class="text-lg lg:text-xl text-white mr-8 lg:mr-auto")
                    | {{ peerNpsScoreComputed }}
                    div(class="text-xs text-grey-2") Avg. Peer PSS
                h1(class="text-lg lg:text-xl text-white mr-auto")
                    | {{ averageSurveysCompleted }}
                    div(class="text-xs text-grey-2") Total Surveys Completed
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { GET_SURVEYS_STATS_OVERALL_AVG_COUNT } from '@/graphql/queries/formStatQueries.gql'
import { useLazyQuery } from '@vue/apollo-composable'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { getScoreText, getTextClass, formatAvgResults } from '@/modules/surveyStats'
import { startOfDay, endOfDay } from 'date-fns'
import camelcaseKeys from 'camelcase-keys'
import SurveySvg from '@/assets/icons/check-list.svg'
import HospitalSvg from '@/assets/icons/hospital.svg'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'Summary',
    components: { SurveySvg, HospitalSvg },
    setup() {
        const { tenantId, dashboardFilter, npsScores } = useGetters(['tenantId', 'dashboardFilter', 'npsScores'])
        const averageSurveysCompleted = ref(0)

        const npsScoreComputed = computed(() => {
            if (npsScores.value?.currentScore === null || npsScores.value?.currentScore === undefined) return 'N/A'
            return npsScores.value?.currentScore
        })
        const peerNpsScoreComputed = computed(() => {
            if (npsScores.value?.peerScore === null || npsScores.value?.peerScore === undefined) return 'N/A'
            return npsScores.value?.peerScore
        })

        const overallAvgCountParams = computed(() => {
            return {
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                tenantId: tenantId.value,
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            }
        })

        const {
            onResult: getOverallAvgCountOnResult,
            onError: getOverallAvgCountOnError,
            refetch: getOverallAvgCountRefetch,
            load: getOverallAvgCountLoad,
            loading: overallAvgCountLoading
        } = useLazyQuery(GET_SURVEYS_STATS_OVERALL_AVG_COUNT, overallAvgCountParams)
        getOverallAvgCountOnResult(({data}) => {
            averageSurveysCompleted.value = 0
            if (data?.avgResults) {
                const avgResults = camelcaseKeys(data?.avgResults, {deep: true})
                avgResults.map((result) => {
                    averageSurveysCompleted.value += result.surveys
                })
            }
        })

        watch(() => dashboardFilter.value, (nextValue, preValue) => {
            if (nextValue != preValue) {
                getOverallAvgCountRefetch()
            }
        })

        getOverallAvgCountLoad()

        return {
            averageSurveysCompleted,
            npsScoreComputed,
            peerNpsScoreComputed,
            getScoreText,
            getTextClass
        }
    },
}
</script>
