import { ref } from '@vue/composition-api'
import { getPointRadius, getYAxisLabelWithoutDecimal } from '@/modules/surveyStats'
import { CHART_OPTION_COLORS, SURVEY_STATS_SCORE_COLOURS } from '@/common/constants'

export const chartData1 = ref({
    labels: [],
    datasets: [
        {
            label: 'Average Peer Score',
            data: [],
            borderColor: '#F57C00',
            backgroundColor: '#F57C00',
            stack: 'combined',
            type: 'scatter',
            pointRadius: (data) => {
                return getPointRadius(data)
            },
            pointHoverRadius: (data) => {
                return getPointRadius(data)
            },
            yAxisID: 'average_survey_score'
        },
        {
            label: 'Average Survey Score',
            data: [],
            borderColor: '#4EBBFF',
            backgroundColor: '#4EBBFF',
            stack: 'combined',
            type: 'scatter',
            pointRadius: (data) => {
                return getPointRadius(data, 6)
            },
            pointHoverRadius: (data) => {
                return getPointRadius(data, 6)
            },
            yAxisID: 'average_survey_score'
        },
        {
            label: 'Surveys Completed',
            data: [],
            backgroundColor: '#1C3F95',
            stack: 'combined',
            barThickness : 43,
            yAxisID: 'surveys_completed'
        },
    ]
})
export const chartData2 = ref({
    labels: ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent' ],
    datasets: [
        {
            label: 'Average Peer Count',
            data: [],
            borderColor: '#932ad3',
            backgroundColor: '#932ad3',
            stack: 'combined',
            pointRadius: (data) => {
                return getPointRadius(data, 6)
            },
            pointHoverRadius: (data) => {
                return getPointRadius(data, 6)
            },
            type: 'scatter'
        },
        {
            label: 'Average Score Count',
            data: [],
            backgroundColor: SURVEY_STATS_SCORE_COLOURS,
            stack: 'combined',
            barThickness : 43
        },
    ]
})
export const avgScoreScales = {
    xAxes: [{
        gridLines: {
            fontSize: 10,
            display: false
        },
        ticks: {
            fontSize: 10,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans",
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0
        },
    }],
    yAxes: [{
        id: 'average_survey_score',
        scaleLabel: {
            display: true,
            labelString: 'Average Survey Score',
            fontSize: 11,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans"
        },
        gridLines: {
            display: true,
            color: CHART_OPTION_COLORS.gridlineColor,
            zeroLineColor: CHART_OPTION_COLORS.gridlineColor
        },
        ticks: {
            fontSize: 10,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans",
            beginAtZero: true,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            stepSize: 0.5,
            min: 0,
            callback: getYAxisLabelWithoutDecimal
        }
    }, {
        id: 'surveys_completed',
        scaleLabel: {
            display: true,
            labelString: 'Surveys Completed',
            fontSize: 11,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans"
        },
        gridLines: {
            color: CHART_OPTION_COLORS.gridlineColor,
            zeroLineColor: CHART_OPTION_COLORS.gridlineColor,
            drawOnChartArea: false
        },
        position: 'right',
        ticks: {
            fontSize: 10,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans",
            beginAtZero: true,
            autoSkip: true,
            stepSize: 5,
            maxRotation: 0,
            minRotation: 0
        }
    }]
}
export const scales2 = {
    xAxes: [{
        gridLines: {
            fontSize: 10,
            display:false
        },
        ticks: {
            fontSize: 10,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans",
        },
    }],
    yAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Score count',
            fontSize: 11,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans"
        },
        gridLines: {
            display: true,
            color: CHART_OPTION_COLORS.gridlineColor,
            zeroLineColor: CHART_OPTION_COLORS.gridlineColor
        },
        ticks: {
            fontSize: 10,
            fontColor: CHART_OPTION_COLORS.axisLabelColor,
            fontFamily: "Open Sans",
            beginAtZero: false,
            callback: (value, index) => {
                if (!value) return ''

                return value
            }
        }
    }]
}

export const chart1Legend = {
    position: 'bottom',
    labels: {
        boxWidth: 12,
        fontColor: CHART_OPTION_COLORS.axisLabelColor,
        font: {
            family: 'Open Sans, sans-serif',
            size: 6
        }
    },
    reverse: true
}
export const scoreChartLegend = {
    position: 'bottom',
    labels: {
        boxWidth: 12,
        fontColor: CHART_OPTION_COLORS.axisLabelColor,
        font: {
            family: 'Open Sans, sans-serif',
            size: 6
        },
        filter: (item, chart) => {
            return item.text !== 'Average Score Count';
        }
    }
}
