<template lang="pug">
    div
        // div(class="relative")
            // div(class="insightsUpgradeModal fixed left-0 right-0 z-10 max-w-960 m-auto bg-navy p-6 rounded")
                // h1.text-center Upgrade to access your survey dashboard!
                // SubscriptionOptions.grid.grid-cols-12.gap-6.mt-5.grid-flow-col
                // .text-center
                    // router-link(
                        // class="w-200 mb-5 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default success"
                        // :to="{name: 'ChangePlan'}"
                    // )
                        // | Change Plan
                        // fa-icon(icon="chevron-right" class="ml-2")
                
            // div.insightsOverlay
            // img(src="@/assets/images/placeholder/insights-chart-example.jpg" alt="Your survey insights" class="m-auto w-full")

        .p-8
            FormSetList(:initial-form-set-id="formSetIdToLoad" @form-set-list-loading="formSetListLoadingHandler")
            //div
            //    | is this an NPS value?{{ isNps(formSetType) }}
            //div
            //    | hasData {{ hasData}}
            //div
            //    | are we loading? {{ formSetListLoading }}
            //div(v-if="hasData && !getAnswerCountLoading && !formSetListLoading")
            div(v-if="isNps(formSetType) && hasData && !formSetListLoading")
                Summary
                //ClinicSummary
                PSSSummary(:loading-nps-score="getPssCountLoading" :loading-nps-message="gettingNpsScoreMessage")
                //TopicSummary
                //CategoryStatistics
                //SurveysCompleted
                PSSQuestionsStats(:loading-nps-score="getPssCountLoading")
            // else if we have racgp
            div(v-else-if="isRcpgSurvey(formSetType) && hasData && !formSetListLoading")
                //SurveySummary
                RACGPMockup(v-if="!getAnswerCountLoading || !getAnswerCountPssLoading" :total-surveys="totalSurveys")
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")
            div(class="m-auto w-full lg:max-w-3/4" v-else-if="!hasData && !formSetListLoading")
                WelcomeMessage(
                    class="mt-8 mb-4 box-bg rounded p-4 grid grid-cols-1 lg:grid-cols-2 flex items-center"
                )

                // NoChartDisplay(
                    // class="mt-8 mb-4 bg-navy-light rounded p-4 grid grid-cols-1 lg:grid-cols-2 flex items-center"
                //)
            .flex.items-center.justify-center.h-20.w-full(v-else)
                Loader(class="w-12 h-12")
</template>

<script>
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'
import ClinicSummary from '@/components/dashboard/ClinicSummary'
import PSSSummary from '@/components/dashboard/PSSSummary'
import Summary from '@/components/dashboard/Summary'
import TopicSummary from '@/components/dashboard/TopicSummary'
import FormSetList from '@/components/dashboard/partials/FormSetList'
import CategoryStatistics from '@/components/dashboard/CategoryStatistics'
import SurveysCompleted from '@/components/dashboard/SurveysCompleted'
import WelcomeMessage from '@/components/dashboard/WelcomeMessage'
import NoChartDisplay from '@/components/dashboard/NoChartDisplay'
import { useLazyQuery } from '@vue/apollo-composable'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Loader from '@/assets/loader.svg'
import camelcaseKeys from 'camelcase-keys'
import PSSQuestionsStats from '@/components/dashboard/PSSQuestionsStats'
import { endOfDay, format, startOfDay } from 'date-fns'
import SubscriptionOptions from '@/components/subscription/SubscriptionOptions'
import { useFormHelpers } from '@/composables/useFormHelpers'
import SurveySummary from '@/components/survey-summary/SurveySummary.vue'
import RACGPMockup from '@/components/dashboard/RACGPMockup'
import { useFormSetStore } from '@/stores/useFormSetStore'
import { storeToRefs } from 'pinia'
import { scoreService } from '@/services/score.service'
import { GET_PSS_COUNTS, GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT } from '@/graphql/queries/formStatQueries.gql'
import { GET_ANSWER_COUNT } from '@/graphql/queries/formAnswerQueries.gql'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'NewDashboard',
    components: {
        PSSQuestionsStats,
        ClinicSummary,
        PSSSummary,
        Summary,
        TopicSummary,
        CategoryStatistics,
        SurveysCompleted,
        FormSetList,
        WelcomeMessage,
        NoChartDisplay,
        Loader,
        SubscriptionOptions,
        SurveySummary,
        RACGPMockup
    },
    setup() {
        const hasData = ref(false)
        const formSetStore = useFormSetStore()
        const gettingNpsScoreMessage = ref('')
        const retry = ref(false)
        const totalSurveys = ref(0)
        const intializing = ref(false)
        const { isRcpgSurvey, isNps } = useFormHelpers()
        const { formSetId: formSetIdToLoad, category } = storeToRefs(formSetStore)
        const { getSurveyStatsByQuestion, getSurveyStatsByQuestionAndCategory} = formSetStore
        const { tenantId, dashboardFilter, formSet } = useGetters([
            'tenantId',
            'dashboardFilter',
            'formSet'
        ])
        const { setNpsScores } = useActions(['setNpsScores'])
        const errorMessage = ref('')
        const haveResult = ref(false)
        const havePssCount = ref(false)
        const formSetListLoading = ref(true)
        const loading = ref(false)

        const formSetType = computed(() => formSet.value?.formType)

        const { getProducts } = useActions(['getProducts'])

        const setLoading = (val) => {
            loading.value = val
        }

        const answerCountParams = computed(() => {
            return {
                tenantId: tenantId.value,
                formSetId: dashboardFilter.value.formSetId,
                startDate: startOfDay(
                    new Date(dashboardFilter.value?.dateRange?.startDate)
                ),
                endDate: endOfDay(
                    new Date(dashboardFilter.value?.dateRange?.endDate)
                ),
            }
        })

        const answerParams = computed(() => {
            return {
                tenantIdCompare: { _eq: tenantId },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            }
        })

        const pssCountsParams = computed(() => {
            return {
                // // removing tenantId for now so that we can get all tenants with this formset id
                // // this is so we can show both the current tenant and their peers
                // tenantIdCompare: { _eq: tenantId.value },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                startDate: dashboardFilter.value?.dateRange?.startDate ?
                    format(new Date(dashboardFilter.value?.dateRange?.startDate), 'yyyy-MM') : '',
                endDate: dashboardFilter.value?.dateRange?.endDate ?
                    format(new Date(dashboardFilter.value?.dateRange?.endDate), 'yyyy-MM') : '',
            }
        })

        const setHasData = (val) => {
            hasData.value = val
        }

        const {
            onResult: getAnswerCountPssOnResult,
            onError: getAnswerCountPssOnError,
            refetch: getAnswerCountPssRefetch,
            load: getAnswerCountPssLoad,
            loading: getAnswerCountPssLoading,
        } = useLazyQuery(GET_ANSWER_COUNT, answerCountParams)
        getAnswerCountPssOnResult((resp) => {
            const { data } = resp
            if (getAnswerCountPssLoading.value || isRcpgSurvey(formSetType.value)) return
            totalSurveys.value = data?.formAnswerCount?.aggregate?.count || 0
            setHasData(!!data?.formAnswerCount?.aggregate?.count)
        })

        const {
            onResult: getAnswerCountOnResult,
            onError: getAnswerCountOnError,
            refetch: getAnswerCountRefetch,
            load: getAnswerCountLoad,
            loading: getAnswerCountLoading,
        } = useLazyQuery(GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT, answerParams)
        getAnswerCountOnResult((resp) => {
            const { data } = resp
            if (getAnswerCountLoading.value || isNps(formSetType.value)) return
            const totalSurveysList = camelcaseKeys(data?.totalSurveys, {deep: true})
            const count = totalSurveysList?.reduce((acc, curr) => {
                acc += Number(curr.surveyCount || 0)
                return acc
            }, 0)
            totalSurveys.value = count
            setHasData(!!count)
        })

        getAnswerCountOnError((error) => {
            totalSurveys.value = 0
            console.log('error', error)
            errorMessage.value =
                'failed to load the count of answers that have been recorded'
        })

        const {
            onResult: getPssCountOnResult,
            onError: getPssCountOnError,
            refetch: getPssCountRefetch,
            load: getPssCountLoad,
            loading: getPssCountLoading,
        } = useLazyQuery(GET_PSS_COUNTS, pssCountsParams)

        getPssCountOnResult((resp) => {
            const { data } = resp
            // if (getPssCountLoading) return
            const npsScores = camelcaseKeys(data, {
                deep: true,
                exclude: [/^\d{4}[\/\-](0?[1-9]|1[012])$/, /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/],
            })
            if (!npsScores) return

            const stats = [ ...npsScores.stats ]

            const scores = scoreService.formatNpsScore(stats, tenantId.value)
            setNpsScores(scores)
            // if we succesfully refetched, let's change it
            if (retry.value) retry.value = false
        })
        getPssCountOnError((error) => {
            // if (getPssCountLoading) return

            // let's try doing a refetch first
            if (!retry.value) {
                retry.value = true
                getPssCountRefetch(pssCountsParams.value)
            } else {
                console.log('error', error)
                gettingNpsScoreMessage.value = 'Could not get the nps scores at this time, please try again later'
            }
        })

        const formSetListLoadingHandler = (isLoading) => {
            formSetListLoading.value = isLoading
        }

        const callGetStats = () => {
            getSurveyStatsByQuestion(
                { _eq: tenantId.value },
                { _eq: dashboardFilter.value.formSetId },
                startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            ).catch((err) => console.log('=== error', err))

            getSurveyStatsByQuestion(
                { _neq: tenantId.value },
                { _eq: dashboardFilter.value.formSetId },
                startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate)),
                false
            ).catch((err) => console.log('=== error', err))
        }

        const callGetCategoryData = () => {
            if (!category.value) return
            getSurveyStatsByQuestionAndCategory(
                { _eq: tenantId.value },
                { _eq: dashboardFilter.value.formSetId },
                { _eq: category.value},
                startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate)),
            ).catch((err) => console.log('=== error', err))
            getSurveyStatsByQuestionAndCategory(
                { _neq: tenantId.value },
                { _eq: dashboardFilter.value.formSetId },
                { _eq: category.value},
                startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate)),
                false
            ).catch((err) => console.log('=== error', err))
        }

        // NOTE: don't use 'immediate' here, as this should only run when the tenant changes
        watch(tenantId, async (nextValue) => {
            // console.log('tenantId keeps changing??', nextValue)
            if (!nextValue || !dashboardFilter.value?.formSetId) return
            if (isNps(formSetType.value)) getPssCountRefetch(pssCountsParams.value).catch((err) => console.log('=== error', err))
            else getAnswerCountRefetch(answerParams.value).catch((err) => console.log('=== error', err))
            // await getNpsScore()
            // callGetStats()
            callGetCategoryData()
            callGetStats()
        })

        watch(dashboardFilter, (nextValue, preValue) => {
            // skip the watch if we don't have a formSetId
            if (!nextValue.formSetId || !tenantId.value || !nextValue?.dateRange?.startDate) return

            console.log('the dashboard filter has changed, getting new value for form set it')
            // intializing.value = true
            if (isNps(formSetType.value)) {
                if (havePssCount.value) {
                    console.log('we some results so lets check if we have an NPS or not', isNps(formSetType.value))
                    getAnswerCountPssRefetch({ ...answerCountParams.value })
                    getPssCountRefetch(pssCountsParams.value)
                } else {
                    getAnswerCountPssLoad()
                    getPssCountLoad()
                    havePssCount.value = true
                }
            } else {
                if (haveResult.value) {
                    getAnswerCountRefetch({ ...answerParams.value })
                } else {
                    getAnswerCountLoad()
                    haveResult.value = true
                }
            }
            callGetStats()
        }, { deep: true })

        watch(category, (newCategory) => {
            if (!newCategory || !dashboardFilter.value?.formSetId) return
            callGetCategoryData()
        }, { immediate: true })

        onBeforeMount(async () => {
            await getProducts()
            callGetStats()
        })

        return {
            hasData,
            getAnswerCountLoading,
            getAnswerCountPssLoading,
            formSetListLoading,
            formSetType,
            formSetIdToLoad,
            getPssCountLoading,
            // gettingNpsScore,
            totalSurveys,
            gettingNpsScoreMessage,
            intializing,
            isRcpgSurvey,
            isNps,
            setHasData,
            formSetListLoadingHandler,
            setLoading,
        }
    },
}
</script>
