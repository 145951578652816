<template lang="pug">
    .rounded.px-4.py-3.box-bg.mb-4
        .flex.items-center.justify-between.border-b-2.border-primary.pb-3
            .flex.items-center
                h1 Patient Satisfaction Score (PSS) Summary

        div(v-if="!loadingCharts && !computedLoadingNpsScore" class="mt-2 grid grid-cols-2 gap-4")
            div(class="col-span-1 h-100")
                MixedChart(
                    class="w-4/5 h-auto mt-4 m-auto"
                    title="Patient Satisfaction Score"
                    :chartData='pssChartData'
                    :scales="pssScoreScales"
                    :height="340"
                    :label="getTooltipText"
                    :legend="pssScoreLegend"
                )
            div(class="col-span-1")
                MixedChart(
                    class="w-4/5 h-auto mt-4 m-auto"
                    title="PSS Count"
                    :chartData='pssCountPerScoreChartData'
                    :scales="pssCountPerScoreScales"
                    :height="340"
                    :label="getTooltipText"
                    :legend="pssScoreLegend"
                )
        div(v-else class="mt-2 grid grid-cols-2 gap-4")
            Loader(
                class="col-span-2 w-100"
            )
            div(class="col-span-2 text-center")
                | Loading NPS scores, this can take up to 2 minutes, please wait...
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { getPointRadius } from '@/modules/surveyStats'
import { CHART_OPTION_COLORS } from '@/common/constants'
import MixedChart from '@/components/charts/MixedChart'
import Loader from '@/assets/loader.svg'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'PSSSummary',
    components: { MixedChart, Loader },
    props: {
        loadingNpsScore: {
            type: Boolean,
            default: false,
        },
        loadingNpsScoreMessage: {
            type: String,
            default: '',
        }
    },
    setup(props) {
        const loadingCharts = ref(false)
        const { npsScores: pssScores } = useGetters(['npsScores'])
        const pssTimeSeries = computed(() => pssScores.value?.graphs?.npsByDate)
        const pssCountPerScore = computed(() => pssScores.value?.graphs?.npsCountByScore)
        const computedLoadingNpsScore = computed(() => props.loadingNpsScore)
        const computedLoadingNpsScoreMessage = computed(() => props.loadingNpsScoreMessage)

        const pssScoreScales = {
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display: false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
            }],
            yAxes: [{
                id: 'pss_graph',
                scaleLabel: {
                    display: true,
                    labelString: 'Patient Satisfaction Score',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    stepSize: 20,
                    min: -100,
                    max: 100,
                }
            }]
        }
        const pssCountPerScoreScales = {
            barThickness: 0.8,
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display: false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            }],
            yAxes: [{
                id: 'pss_graph',
                scaleLabel: {
                    display: true,
                    labelString: 'Count',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: true,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    stepSize: 1000,
                    min: 0,
                }
            }]
        }

        const pssChartData = ref({
            labels: [],
            datasets: [
                {
                    barThickness: 30,
                    label: 'Clinic PSS',
                    data: [],
                    borderColor: '#4EBBFF',
                    backgroundColor: '#4EBBFF',
                    stack: 'combined',
                    yAxisID: 'pss_graph',
                    order: 2
                },
                {
                    label: 'Peer PSS',
                    data: [],
                    borderColor: '#F57C00',
                    backgroundColor: '#F57C00',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    yAxisID: 'pss_graph',
                    order: 1
                },
            ]
        })
        const pssCountPerScoreChartData = ref({
            labels: [],
            datasets: [
                {
                    barThickness: 30,
                    label: 'Clinic PSS Count',
                    data: [],
                    borderColor: '#4EBBFF',
                    backgroundColor: '#4EBBFF',
                    stack: 'combined',
                    yAxisID: 'pss_graph',
                    order: 2
                },
                {
                    label: 'Peer PSS Count',
                    data: [],
                    borderColor: '#F57C00',
                    backgroundColor: '#F57C00',
                    stack: 'combined',
                    type: 'scatter',
                    pointRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data, 6)
                    },
                    yAxisID: 'pss_graph',
                    order: 1
                },
            ]
        })

        const pssScoreLegend = {
            position: 'bottom',
            labels: {
                boxWidth: 12,
                fontColor: CHART_OPTION_COLORS.axisLabelColor,
                font: {
                    family: 'Open Sans, sans-serif',
                    size: 6
                }
            },
            reverse: false
        }

        const getTooltipText = (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`
        }

        watch([pssTimeSeries, pssCountPerScore], ([timeSeries, countPerScore]) => {
            loadingCharts.value = true
            const chartData = {...(pssChartData.value || {})}
            const countPerScoreChartData = {...(pssCountPerScoreChartData.value || {})}
            chartData.labels = [...(timeSeries?.labels || [])]
            chartData.datasets[0].data = [...(timeSeries?.currentScores || [])]
            chartData.datasets[1].data = [...(timeSeries?.peerScores || [])]
            countPerScoreChartData.labels = [...(countPerScore?.labels || [])]
            countPerScoreChartData.datasets[0].data = [...(countPerScore?.currentCountByScore || [])]
            countPerScoreChartData.datasets[1].data = [...(countPerScore?.peersCountByScore || [])]
            pssChartData.value = {...chartData}
            pssCountPerScoreChartData.value = {...countPerScoreChartData}
            loadingCharts.value = false
        }, { deep: true, immediate: true })
        
        return {
            pssChartData,
            pssScoreScales,
            pssCountPerScoreChartData,
            pssCountPerScoreScales,
            pssScoreLegend,
            loadingCharts,
            computedLoadingNpsScore,
            computedLoadingNpsScoreMessage,
            getTooltipText
        }
    }
}
</script>
