<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import { CHART_OPTION_COLORS } from '@/common/constants'

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
        scales: {
            type: Object,
            default: null
        },
        label: null,
        legend: {
            type: Object,
            default: () => {
                return {
                    position: 'bottom',
                    labels: {
                        boxWidth: 12,
                        fontColor: CHART_OPTION_COLORS.axisLabelColor,
                        font: {
                            family: 'Open Sans, sans-serif',
                            size: 6
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.renderMixedChart()
    },
    methods: {
        renderMixedChart() {
            this.renderChart(
                this.chartData,
                {
                    title: {
                        display: true,
                        text: this.title,
                        fontSize: 13,
                        fontColor: CHART_OPTION_COLORS.titleColor,
                        fontFamily: "Open Sans",
                    },
                    legend: {...this.legend},
                    plugins: {
                        datalabels: {
                            display: false
                        }
                    },
                    tooltips: {
                        yAlign: 'bottom',
                        xAlign: 'center',
                        titleAlign: 'center',
                        bodyAlign: 'center',
                        custom: (tooltip) => {
                            if (tooltip) tooltip.displayColors = false
                        },
                        callbacks: {
                            title: () => '',
                            label: this.label ? this.label : (tooltipItem, data) => {
                                return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`
                            }
                        }
                    },
                    scales: this.scales,
                    responsive: true,
                    maintainAspectRatio: false
                }
            )
        }
    },
    watch: {
        chartData() {
            // this.renderMixedChart()
            this.$data._chart.update()
        },
        title() {
            // this.renderMixedChart()
            this.$data._chart.update()
        }
    }
}
</script>
