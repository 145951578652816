<template lang="pug">
    div(class="m-auto text-text-color mt-8 mb-8 box-bg rounded p-4 lg:grid-cols-2 nps-questions")
        h2(class="border-bottom-3 pt-4 pb-4") Patient Satisfaction Score Questions
        div(v-if="!getPssScoreLoading" class="m-auto mt-8 grid grid-cols-3 gap-4")
            div(class="bar-chart text-center")
                h4(class="mb-5") What needs urgent attention now?
                PSSQuestions(:chartData="chartData3" v-if="!loading")
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")
            div(class="bar-chart text-center")
                h4(class="mb-5") In what areas could we improve?
                PSSQuestions(:chartData="chartData2" v-if="!loading")
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")
            div(class="bar-chart text-center")
                h4(class="mb-5") What did we do well?
                PSSQuestions(:chartData="chartData1" v-if="!loading")
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")
        div(v-else class="mt-2 grid grid-cols-2 gap-4")
            Loader(
                class="col-span-2 w-100"
            )
            div(class="col-span-2 text-center")
                | Loading charts, please wait...
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import camelcaseKeys from 'camelcase-keys'
import PSSQuestions from '@/components/dashboard/PSSQuestions'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Loader from '@/assets/loader.svg'
import { endOfDay, startOfDay } from 'date-fns'
import { scoreService } from '@/services/score.service'
import { useLazyQuery } from '@vue/apollo-composable'
import { GET_PSS_SCORE_STATS } from '@/graphql/queries/formStatQueries.gql'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'NPSQuestionsStats',
    components: { PSSQuestions, Loader },
    props: {
        loadingNpsScore: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const loading = ref(false)
        const statsFirstLoad = ref(true)
        const computedLoadingNpsScore = computed(() => props.loadingNpsScore)
        const { tenantId, dashboardFilter } = useGetters(['tenantId', 'dashboardFilter'])

        const chartData1= ref([])
        const chartData2= ref([])
        const chartData3= ref([])

        const npsStatsParams = computed(() => {
            return {
                tenantIdCompare: { _eq: tenantId.value },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId},
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate)),
            }
        })

        const {
            onResult: getPssScoreOnResult,
            onError: getPssScoreOnError,
            refetch: getPssScoreRefetch,
            load: getPssScoreLoad,
            loading: getPssScoreLoading
        } = useLazyQuery(GET_PSS_SCORE_STATS, npsStatsParams)

        getPssScoreOnResult(({ data }) => {
            if (getPssScoreLoading.value) return
            const result = camelcaseKeys(data?.scores || [], { deep: true })
            chartData1.value = result.filter(e => {
                if (e.question === "What did we do well?") return e
            })
            chartData2.value = result.filter(e => {
                if (e.question === "In what areas could we improve?") return e
            })
            chartData3.value = result.filter(e => {
                if (e.question === "What needs urgent attention now?") return e
            })
        })

        getPssScoreOnError(() => {
            console.log('error getting the nps stats')
        })

        const getQuestionStats = (filter) => {
            if (!filter.formSetId) return
            if (statsFirstLoad.value) {
                getPssScoreLoad()
            } else {
                getPssScoreRefetch(npsStatsParams.value)
                statsFirstLoad.value = false
            }
            // getSurveyStats().catch((err) => console.error(err))
        }

        onMounted(() => {
            if (!loading.value) getQuestionStats(dashboardFilter.value)
        })

        watch([dashboardFilter, tenantId, loading], (nextValue) => {
            // skip the watch if we don't have a formSetId
            const [filter, tenant, stillLoading] = nextValue
            if (!filter.formSetId) return

            if (!stillLoading) getQuestionStats(filter)
        }, { deep: true, immediate: true })

        return {
            loading,
            chartData1,
            chartData2,
            chartData3,
            computedLoadingNpsScore,
            getPssScoreLoading
        }
    }
}
</script>
