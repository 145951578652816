<template lang="pug">
    .rounded.px-4.py-3.box-bg.mb-4
        .flex.items-center.justify-between.border-b-2.border-primary.pb-3
            .flex.items-center
                h1 Clinic Summary

            // // hiding the button for now
            //v-btn(
            //    color="success"
            //    depressed
            //    :class="{'pointer-events-none': reportDownloading}"
            //    @click="downloadReport"
            //)
            //    fa-icon(:icon="downloadReportIcon" :spin="reportDownloading" class="mr-2")
            //    |  Accreditation Survey Report
        div(class="mt-2 grid lg:grid-cols-1 xl:grid-cols-3 gap-4")
            div(class="col-span-1 xl:col-span-2")
                div(v-if="!scoreChartLoading && !surveyStateLoading")
                    .mt-4
                        MixedChart(
                            title="Average Survey Scores & Surveys Completed"
                            :chartData='averageScoreChartData'
                            :scales="avgScoreScales"
                            :height="340"
                            :label="getTooltipText"
                            :legend="chart1Legend"
                        )
                Loader(
                    v-else
                    class="w-100"
                )
            div(class="col-span-1")
                div(v-if="!scoreChartLoading")
                    .mt-4
                        MixedChart(
                            title="Average Survey Score Count"
                            :chartData='chartData2'
                            :scales="scales2"
                            :height="340"
                            :label="getTooltipText"
                            :legend="scoreChartLegend"
                        )
                Loader(
                    v-else
                    class="w-100"
                )
</template>

<script>
import MixedChart from '@/components/charts/MixedChart'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import Filters from '@/components/dashboard/partials/Filters'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery } from '@vue/apollo-composable'
import { GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT } from '@/graphql/queries/formStatQueries.gql'
import { formatTotalCompletedResults, getGraphDataByDatesTotal, getAverageGraphDataByDates } from '@/modules/surveyStats'
import { getDateLabels } from '@/modules/dateHelpers'
import Loader from '@/assets/loader.svg'
import { startOfDay, endOfDay } from 'date-fns'
import { getReport } from '@/services/reports'
import {
    avgScoreScales, chart1Legend,
    chartData1,
    chartData2,
    scales2, scoreChartLegend
} from '@/components/dashboard/partials/clinicSummary.constants'
import { storeToRefs } from 'pinia'
import { useFormSetStore } from '@/stores/useFormSetStore'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'ClinicSummary',
    components: { MixedChart, Filters, Loader },
    setup() {
        const { tenantId, dashboardFilter } = useGetters(['tenantId', 'dashboardFilter'])
        const formSetStore = useFormSetStore()
        const { practiceSurveyStats, peerSurveyStats, surveyStateLoading } = storeToRefs(formSetStore)
        
        const labels = ref([])
        const chartDataMap = ref({
            peerScore: [],
            surveyScore: [],
            surveysCompleted: []
        })
        const averageScoreChartData = computed(() => {
            const dataSets = [...chartData1.value.datasets]
            dataSets[0].data = [...chartDataMap.value.peerScore]
            dataSets[1].data = [...chartDataMap.value.surveyScore]
            dataSets[2].data = [...chartDataMap.value.surveysCompleted]
            return { labels: labels.value, datasets: dataSets }
        })

        const params = computed(() => {
            return {
                tenantIdCompare: { _eq: tenantId.value },
                formSetIdCompare: { _eq: dashboardFilter.value.formSetId },
                startDate: startOfDay(new Date(dashboardFilter.value?.dateRange?.startDate)),
                endDate: endOfDay(new Date(dashboardFilter.value?.dateRange?.endDate))
            }
        })

        const dateFormat = ref('MMM dd, yyyy')
        // const reportDownloading = ref(false)
        // const downloadReportIcon = computed(() => {
        //     return reportDownloading.value ? 'spinner' : 'download'
        // })

        const getTooltipText = (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value} (${tooltipItem.label})`
        }

        // const downloadReport = async () => {
        //     reportDownloading.value = true
        //     await getReport(dashboardFilter.value.formSetId)
        //     reportDownloading.value = false
        // }

        const {
            onResult: getStatsOnResult,
            // onError: getStatsOnError,
            refetch: getStatsRefetch,
            load: getStatsLoad,
            loading: scoreChartLoading
        } = useLazyQuery(GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT, params)

        getStatsOnResult(({ data }) => {
            if (scoreChartLoading.value) return

            const surveys = formatTotalCompletedResults(data?.totalSurveys, dateFormat.value)
            if (!surveys) return

            const temp = getGraphDataByDatesTotal(labels.value, surveys)
            chartDataMap.value.surveysCompleted = temp
        })

        watch(practiceSurveyStats, ({ data }) => {
            if (!data?.scores) return

            const { scores } = data
            let avgCount = [0, 0, 0, 0, 0] // [Poor, Fair, Good, Very Good, Excellent]
            scores.map(({ score }) => {
                if (score >= 4) avgCount[3] += 1
                else if (score >= 3) avgCount[2] += 1
                else if (score >= 2) avgCount[1] += 1
                else avgCount[0] += 1
            })
            chartData2.value.datasets[1].data = avgCount
            chartData2.value = {...chartData2.value}

            const averageSurveyScore = getAverageGraphDataByDates(labels.value, data.scores, dateFormat.value)
            chartDataMap.value.surveyScore = averageSurveyScore
        }, { deep: true })

        watch(peerSurveyStats, ({ data }) => {
            if (!data?.scores) return

            const { scores } = data
            let avgCount = [0, 0, 0, 0, 0] // [Poor, Fair, Good, Very Good, Excellent]
            scores.map(({ score }) => {
                if (score >= 4) avgCount[3] += 1
                else if (score >= 3) avgCount[2] += 1
                else if (score >= 2) avgCount[1] += 1
                else avgCount[0] += 1
            })
            const tempChart2Data = { ...chartData2.value }
            tempChart2Data.datasets[0].data = avgCount
            chartData2.value = { ...tempChart2Data }

            const averagedGraphData = getAverageGraphDataByDates(labels.value, data.scores, dateFormat.value)
            chartDataMap.value.peerScore = averagedGraphData
        }, { deep: true })

        watch(() => dashboardFilter.value, (nextValue, prevValue) => {
            // const chart = {...chartData1.value}
            const { startDate, endDate } = nextValue.dateRange
            
            const { labels: newLabels, format } = getDateLabels(startDate, endDate)
            labels.value = [...newLabels]
            // chart.labels = newLabels
            dateFormat.value = format
            // chartData1.value = {...chart}

            if (nextValue !== prevValue) {
                getStatsRefetch()
            }
        }, { deep: true })

        watch(tenantId, () => {
            if (tenantId.value) {
                getStatsRefetch()
            }
        })

        onMounted(() => {
            getStatsLoad()

            if (!dashboardFilter.value?.dateRange) return

            const { dateRange: { startDate, endDate } } = dashboardFilter.value
            if (!startDate || !endDate) return

            const { labels: newLabels, format } = getDateLabels(startDate, endDate)
            labels.value = [...newLabels]
            dateFormat.value = format
        })

        return {
            chartData1,
            chartData2,
            avgScoreScales,
            scales2,
            scoreChartLoading,
            // reportDownloading,
            // downloadReportIcon,
            surveyStateLoading,
            scoreChartLegend,
            chart1Legend,
            averageScoreChartData,
            getTooltipText,
            // downloadReport
        }
    }
}
</script>
