<template lang="pug">
    div
        v-data-table(
            single-select
            item-key="topicId"
            :headers="headers"
            :items="tableData"
            hide-default-footer
            class="a-table w-full capitalize"
        )
            template(v-slot:item.avgScore="{item}")
                span(class="font-weight-bold" :class="getTextClass(item.avgScore)") {{ `${getScoreText(item.avgScore)} (${item.avgScore})` }}
            template(v-slot:item.avgPeerScore="{item}")
                span(class="font-weight-bold" :class="getTextClass(item.avgPeerScore)") {{ `${getScoreText(item.avgPeerScore)} (${item.avgPeerScore})` }}
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { getScoreText, getTextClass } from '@/modules/surveyStats'

export default {
    name: 'TopicList',
    props: {
        tenantScore: {
            type: Object,
            default: () => {}
        },
        peerScore: {
            type: Object,
            default: () => {}
        },
        categories: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const localTenantScore = ref(props.tenantScore)
        const localPeerScore = ref(props.peerScore)

        const headers = ref([
            {text: 'Topic', align: 'Left', value: 'name', class: ''},
            {text: 'Avg. Score', align: 'Left', value: 'avgScore', class: ''},
            {text: 'Avg. Peer Score', align: 'Left', value: 'avgPeerScore', class: ''}
        ])
        const tableData = ref([])

        const setTableData = () => {
            setValues(localTenantScore.value, 'avgScore')
            setValues(localPeerScore.value, 'avgPeerScore')
        }
        const setValues = (obj, tableDataKey) => {
            const keys = Object.keys(obj)

            tableData.value.map((row) => { row[tableDataKey] = 0 })
            for (let key of keys) {
                const idx = tableData.value.findIndex(td => td.name === key)
                if (idx > -1) tableData.value[idx][tableDataKey] = obj[key]
            }
        }

        const setCategories = (categoryList) => {
            const tableDataArr = []
            let idx = 0
            for(let category of categoryList) {
                tableDataArr.push({ topicId: idx, name: category, avgScore: 0, avgPeerScore: 0 })
                idx++
            }
            tableData.value = [...tableDataArr]
        }

        onMounted(() => {
            setCategories(props.categories)
            localTenantScore.value = props.tenantScore
            localPeerScore.value = props.peerScore
        })

        watch(() => props.categories, (nextValue) => {
            if (nextValue?.length) {
                setCategories(nextValue)
                setTableData()
            }
        })

        // for now, we are just updating the current table
        watch(() => props.tenantScore, (nextValue) => {
            localTenantScore.value = nextValue
            setTableData()
        })
        watch(() => props.peerScore, (nextValue) => {
            localPeerScore.value = nextValue
            setTableData()
        })
        return {
            headers,
            tableData,
            getScoreText,
            getTextClass
        }
    },
}
</script>
