import { groupBy } from '@/modules/utilFns'
import camelcaseKeys from 'camelcase-keys'
import { addDays, format, isAfter, isBefore, isEqual, parse, parseISO } from 'date-fns'
import { formatInTimeZone, parseInUTC } from '@/modules/dateHelpers'

const formatDateResults = ({ data }, dateFormat) => {
    if (!dateFormat) dateFormat = 'MMM yyyy'

    const avgScoreByDate = {}
    const scores = camelcaseKeys(data?.scores, {deep: true})

    const dateMap = groupBy(scores, item => format(parseISO(item.updatedAt), dateFormat))
    for (let [key, value] of dateMap) {
        if (!value?.length) continue

        avgScoreByDate[key] = value.reduce((acc, curr) => {
            acc += curr.score
            return acc
        }, 0) / value.length
        avgScoreByDate[key] = Math.round((avgScoreByDate[key] + Number.EPSILON) * 100) / 100
    }

    return avgScoreByDate
}

export const formatTotalCompletedResults = (totalSurveys, dateFormat) => {
    if (!dateFormat) dateFormat = 'MMM yyyy'

    const totalByDate = {}
    const totals = camelcaseKeys(totalSurveys, {deep: true})

    const dateMap = groupBy(totals, item => format(parseISO(item.day), dateFormat))
    for (let [key, value] of dateMap) {
        if (!value?.length) continue

        totalByDate[key] = value.reduce((acc, curr) => {
            acc += curr.surveyCount
            return acc
        }, 0)
    }

    return totalByDate
}


const formatResultsForClinicSummary = ({ data }, dateFormat) => {
    if (!data?.scores) return {}

    const scores = camelcaseKeys(data?.scores, {deep: true})
    const categoryMap = groupBy(scores, item => item.category)
    const totalsByDate = {}
    const avgScoreByDate = formatDateResults({ data }, dateFormat)

    for (let [key, value] of categoryMap) {
        if (!value?.length) continue

        for (let score of value) {
            if (!score?.createdAt) continue

            const date = format(parseISO(score?.createdAt), 'MMM yyyy')
            if (!totalsByDate[date]) totalsByDate[date] = 1
            else totalsByDate[date] += 1
        }
    }

    return {
        totalsByDate,
        avgScoreByDate
    }
}

const formatResultsForRacgpTotalSurveys = (data, dateFormat, calcAvg) => {
    if (!data?.scores) return {}

    const scores = camelcaseKeys(data?.scores, {deep: true})
    const totalSurveyByDate = {}

    scores.map((score) => {
        // const date = format(parseISO(score?.day), dateFormat)
        // changed to using a function that updates the timezone
        const date = formatInTimeZone(score?.day, dateFormat, 'Australia/Sydney')

        if (!totalSurveyByDate[date]) totalSurveyByDate[date] = 1
        else totalSurveyByDate[date] += 1
    })
    if (calcAvg) {
        const ids = scores.map((d) => d.tenantId)
        let uniqueIds = []
        if (ids?.length) {
            uniqueIds = [... new Set(ids)]
            const idsLength = uniqueIds.length
            const keys = Object.keys(totalSurveyByDate)
            keys.forEach((key) => {
                totalSurveyByDate[key] /= (idsLength || 1)
            })
        }
    }

    return totalSurveyByDate
}

const formatResultsForTotalSurveys = (data, dateFormat) => {
    if (!data?.scores) return {}

    const scores = camelcaseKeys(data?.scores, {deep: true})
    const totalScoresByDate = {}
    const totals = {}
    let peerTotal = 0
    let currentTotal = 0
    scores.map((s) => {
        // const date = format(parseISO(s?.day), dateFormat)
        // changed to using a function that updates the timezone
        const date = formatInTimeZone(s?.day, dateFormat, 'Australia/Sydney')

        if (!totalScoresByDate[date]) totalScoresByDate[date] = s.score
        else totalScoresByDate[date] += s.score
        totals[date] += 1
    })
    let keys = Object.keys(totals)
    keys.forEach((key) => {
        totalScoresByDate[key] /= (totals[key] || 1)
    })
    return { ...totalScoresByDate }
}

const formatAvgResults = ({ data }) => {
    if (!data?.avgResults) return {labels: [], finalResult: {}}

    const averages = camelcaseKeys(data?.avgResults, {deep: true})
    const averageMap = groupBy(averages, item => item.category)
    const labels = averageMap.keys()
    const finalResult = {}
    for (let [key, value] of averageMap) {
        if (!value?.length) continue

        finalResult[key] = value.reduce((acc, curr) => {
            acc += curr.score
            return acc
        }, 0) / value.length
        // now we round the value
        // finalResult[key] = finalResult[key].toFixed(2)
        finalResult[key] = Math.round((finalResult[key] + Number.EPSILON) * 100) / 100
    }
    return {
        labels,
        finalResult
    }
}
export const getGraphDataByDatesTotal = (currentLabels, scoreByDate) => {
    let dataSet = []
    const showBy7days = currentLabels[0]?.includes('~')

    if (showBy7days) {
        for (let index = 0; index < currentLabels.length; index++) {
            const [ startDate, endDate ] = currentLabels[index].replace(' ', '').split('~')
            let count = 0
            dataSet[index] = 0

            Object.keys(scoreByDate).map((date) => {
                const newDate = new Date(date)
                if (!isAfter(new Date(startDate), newDate) && (isAfter(new Date(endDate), newDate) || isEqual(new Date(endDate), newDate)) ) {
                    dataSet[index] += scoreByDate[date]
                    count++
                }
            })

            if (!count) dataSet[index] = 0
            // Not sure why this is finding the average over the days?
            else dataSet[index] = dataSet[index]
        }
    } else {
        for (let index = 0; index < currentLabels.length; index++) {
            const keysIncludeLabel = Object.keys(scoreByDate).includes(currentLabels[index])
            if (keysIncludeLabel) dataSet[index] = scoreByDate[currentLabels[index]]
            else dataSet[index] = 0
        }
    }

    return dataSet
}

const getGraphDataByDates = (currentLabels, scoreByDate) => {
    let dataSet = []
    const showBy7days = currentLabels[0]?.includes('~')

    if (showBy7days) {
        for (let index = 0; index < currentLabels.length; index++) {
            const [ startDate, endDate ] = currentLabels[index].replace(' ', '').split('~')
            let count = 0
            dataSet[index] = 0

            Object.keys(scoreByDate).map((date) => {
                const newDate = new Date(date)
                if (!isAfter(new Date(startDate), newDate) && (isAfter(new Date(endDate), newDate) || isEqual(new Date(endDate), newDate)) ) {
                    dataSet[index] += scoreByDate[date]
                    count++
                }
            })

            if (!count) dataSet[index] = 0
            // Not sure why this is finding the average over the days?
            else dataSet[index] = (dataSet[index] / count).toFixed(1)
        }
    } else {
        for (let index = 0; index < currentLabels.length; index++) {
            const keysIncludeLabel = Object.keys(scoreByDate).includes(currentLabels[index])
            if (keysIncludeLabel) dataSet[index] = scoreByDate[currentLabels[index]]
            else dataSet[index] = 0
        }
    }

    return dataSet
}

// TODO: Refactor this later
const getAverageGraphDataByDates = (currentLabels, rows, dateFormat = 'yyyy-MM-dd') => {
    let dataSet = []
    const showBy7days = currentLabels[0]?.includes('~')
    rows = camelcaseKeys(rows)

    if (showBy7days) {
        for (let label of currentLabels) {
            const [ startDate, endDate ] = label.replace(' ', '').split('~')
            const start = new Date(startDate)
            const end = new Date(endDate)
            const endPlusOne = addDays(new Date(end), 1)
            let total = 0, count = 0
            for (let row of rows) {
                const datOrUpdated = row?.updatedAt || row?.day
                const rowDate = parseInUTC(datOrUpdated, dateFormat, 'Australia/Sydney')
                if (isAfter(rowDate, start) && isBefore(rowDate, endPlusOne)) {
                    total += row.score
                    count++
                }
            }
            dataSet.push((total / (count || 1) + Number.EPSILON).toFixed(1))
        }
    } else {
        for (let label of currentLabels) {
            let total = 0, count = 0
            const dateLabel = parse(label, dateFormat, new Date())
            for (let row of rows) {
                if (row == null) continue
                // let currentDate = new Date(row?.day)
                const datOrUpdated = row?.updatedAt || row?.day
                const currentDate = parseInUTC(datOrUpdated, dateFormat, 'Australia/Sydney')
                if (!currentDate) continue

                if (format(currentDate, dateFormat) === format(dateLabel, dateFormat)) {
                    total += row.score
                    count++
                }
            }
            dataSet.push((total / (count || 1) + Number.EPSILON).toFixed(1))
        }
    }

    return dataSet
}

const getAverageGraphDataByDatesRefactored = (currentLabels, rows, dateFormat = 'yyyy-MM-dd') => {
    let dataSet = []
    const showBy7days = currentLabels[0]?.includes('~')
    rows = camelcaseKeys(rows)

    if (showBy7days) {
        for (let label of currentLabels) {
            const [ startDate, endDate ] = label.replace(' ', '').split('~')
            const start = new Date(startDate)
            const end = new Date(endDate)
            const endPlusOne = addDays(new Date(end), 1)
            let total = 0, count = 0
            for (let row of rows) {
                const rowDate = parseInUTC(row?.day, dateFormat, 'Australia/Sydney')
                if (isAfter(rowDate, start) && isBefore(rowDate, endPlusOne)) {
                    total += row.score
                    count++
                }
            }
            dataSet.push((total / (count || 1) + Number.EPSILON).toFixed(2))
        }
    } else {
        for (let label of currentLabels) {
            let total = 0, count = 0
            const dateLabel = parse(label, dateFormat, new Date())
            for (let row of rows) {
                if (row == null) continue
                // let currentDate = new Date(row?.day)
                const currentDate = parseInUTC(row?.day, dateFormat, 'Australia/Sydney')
                if (!currentDate) continue

                if (format(currentDate, dateFormat) === format(dateLabel, dateFormat)) {
                    total += row.score
                    count++
                }
            }
            dataSet.push((total / (count || 1) + Number.EPSILON).toFixed(2))
        }
    }

    return dataSet
}

const getPeersCount = (items) => {
    items = camelcaseKeys(items, { deep: true })
    const dataMap = groupBy(items, item => item.tenantId)
    const peers = {}
    
    for (let [key, value] of dataMap) {
        if (!value?.length) continue
        
        if(!peers[key]) peers[key] = 1
        else peers[key] += 1
    }

    const keyCount = Object.keys(peers).length
    
    return isNaN(items.length / keyCount) ? 0 : items.length / keyCount
}

const getScoreText = (score) => {
    if (score >= 5) return 'Excellent'
    if (score >= 4) return 'Very Good'
    if (score >= 3) return 'Good'
    if (score >= 2) return 'Fair'
    if (score >= 1) return 'Poor'
    else return 'N/A'
}

const getTextClass = (score) => {
    if (score >= 5) return 'text-green'
    if (score >= 4) return 'text-light-blue'
    if (score >= 3) return 'text-blue'
    if (score >= 2) return 'text-yellow'
    if (score >= 1) return 'text-red'
    else return 'text-off-white'
}

const average = arr => arr.reduce((a,b) => a + b, 0) / arr.length;

const getScoresByCategory = (data) => {
    const { scores } = data
    const scoresByCategory = {}

    scores.map((score) => {
        if (scoresByCategory[score.category]) scoresByCategory[score.category].push(score.score)
        else scoresByCategory[score.category] = [score.score]
    })

    Object.keys(scoresByCategory).map((key) => {
        scoresByCategory[key] = parseFloat(average(scoresByCategory[key]).toFixed(1))
    })

    return scoresByCategory
}

const getPointRadius = (data, defaultRadius = 3) => {
    const dataIndex = data?.dataIndex
    const dataset = data?.dataset

    if (parseFloat(dataset.data[dataIndex])) return defaultRadius

    return 0
}

const getYAxisLabelWithoutDecimal = (value, index) => {
    if (value != 0) {
        if (`${value}`.split('.').length < 2) return value
        else return ''
    } else {
        return ''
    }
}

const getYAxisLabel = (value, index) => {
    if (!value) return ''
    return value
}

export {
    formatResultsForClinicSummary,
    formatResultsForTotalSurveys,
    formatAvgResults,
    formatDateResults,
    getGraphDataByDates,
    getPeersCount,
    getScoreText,
    getTextClass,
    getAverageGraphDataByDates,
    getAverageGraphDataByDatesRefactored,
    getScoresByCategory,
    getPointRadius,
    getYAxisLabelWithoutDecimal,
    getYAxisLabel,
    formatResultsForRacgpTotalSurveys
}
