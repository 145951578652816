import { sortDates } from '@/utils/sortingFns'

export default class ScoreService {

    formatNpsScore(scoreList, tenantId) {
        if (!scoreList.length) return null

        let currentPromoters = 0, currentDetractors = 0, currentCount = 0,
            currentScore = 0, peerPromoters = 0, peerDetractors = 0,
            peerCount = 0, peerScore = 0
        //  here we loop over the current keys
        const current = scoreList.filter(sl => sl.tenantId === tenantId)
        // for now, these are all the peers by id
        const peers = scoreList.filter(sl => sl.tenantId !== tenantId)

        const currentScores = []
        const peerScores = []
        const currentCountByScore = current.reduce((accum, c) => {
            let parsed = c.countPerScore
            if (parsed.indexOf('{') > -1) {
                parsed = parsed.replace(/{/g, '[').replace(/}/g, ']')
            }
            const countPerScore = JSON.parse(parsed)

            if (!accum.length) return countPerScore
            else return accum.map((a, i) => a + countPerScore[i])
        }, [])
        const currentCountByScoreKeys = currentCountByScore.map((_, i) => i)

        let peersCountPerScore = []

        let allDatesSet = new Set(current.map(c => c.month))
        for (const p of peers) {
            allDatesSet.add(p.month)
        }

        //now we sort the keys
        const allDates = Array.from(allDatesSet).sort(sortDates)
        // needs to be at least 1 so we don't divide by 0
        const totalNumOfOthers = new Set(peers.map(p => p.tenantId)).size || 1
        allDates.forEach((d) => {
            const foundPeers = peers.filter((p) => p.month === d)
            let currentPeerScore = null

            if (!foundPeers?.length) {
                peerScores.push(0)
                currentPeerScore = 0
            } else {
                foundPeers.forEach((peer) => {
                    let promoters = peer.promoters
                    let detractors = peer.detractors
                    let totalCount = peer.totalCount
                    currentPeerScore = (currentPeerScore || 0) + Math.round(((promoters - detractors) / totalCount) * 100)
                })
            }
            peerScores.push(Math.round(currentPeerScore / totalNumOfOthers))
            peerScore += peerScores.at(-1) || 0
        })

        peerScore = Math.round(peerScore / (allDates.length || 1))
        // let's get the count by score for the
        peers.forEach((peer) => {
            let parsed = peer.countPerScore
            if (parsed.indexOf('{') > -1) {
                parsed = parsed.replace(/{/g, '[').replace(/}/g, ']')
            }
            const countPerScore = JSON.parse(parsed)
            if (!peersCountPerScore.length) {
                peersCountPerScore = [...countPerScore]
            } else {
                peersCountPerScore = countPerScore.map((num, idx) => num + peersCountPerScore[idx])
            }
        })
        peersCountPerScore = peersCountPerScore.map((num) => Math.round(num / totalNumOfOthers) )
        // peersCountPerScore = peersCountPerScore.map((num) => Math.round(num) )

        for (const dateStr of allDates) {
            const foundList = current.filter(f => f.month === dateStr)
            if (!foundList.length) {
                currentScores.push(0)
                continue
            }
            let currentScore = null
            for (const c of foundList) {
                let promoters = c.promoters
                let detractors = c.detractors
                let totalCount = c.totalCount
                currentScore = (currentScore || 0) + Math.round(((promoters - detractors) / totalCount) * 100)
            }

            currentScores.push(currentScore / foundList.length)
        }

        for (const value of current) {
            currentPromoters += value.promoters
            currentDetractors += value.detractors
            currentCount += value.totalCount
        }

        if (currentCount) {
            const percentPromoters = Math.round((currentPromoters / currentCount) * 100)
            const percentDetractors = Math.round((currentDetractors / currentCount) * 100)
            currentScore = percentPromoters - percentDetractors
        }

        return {
            currentScore, peerScore,
            graphs: {
                npsByDate: {
                    labels: allDates,
                    currentScores,
                    peerScores
                },
                npsCountByScore: {
                    labels: currentCountByScoreKeys,
                    currentCountByScore: currentCountByScore,
                    peersCountByScore: peersCountPerScore,
                }
            }
        }
    }
}

export const scoreService = new ScoreService()
