<script>
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: {
        chartData: {
            type: Array,
            default: () => {
                return []
            }
        },
        labels: [
            ['Making an', 'appointment'],
            ['Wait time at the', 'medical centre'],
            ['Reception', 'Staff'],
            ['Communication & Care', 'provided by health...'],
            ['Cleanliness & comfort', 'of premises an...'],
            ['Privacy &', 'Confidentiality'],
            ['Understanding of', 'my entire health needs']
        ],
        title: {
            type: String,
            default: 'What did we do well?'
        },
        scope: {
            type: Object,
            default: null
        },
        showDataLabels: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Count'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        setBackgroundColor: {
            type: String,
            default: '#4ebbff'
        },
        maxHeightColumn: {
            type: [Number, undefined],
            default: undefined
        },
        setTextColor: {
            type: String,
            default: '#9AA4B3'
        },
        setLabelColor: {
            type: String,
            default: 'rgba(255,255,255,0.6)'
        },
        setLineColor: {
            type: String,
            default: 'rgba(100,200,250,0.2)'
        },
    },
    mounted() {
        const data = this.$props.chartData
        const AnswerArr = [
            'MAKING_AN_APPOINTMENT',
            'WAIT_TIME_AT_THE_MEDICAL_CENTRE',
            'RECEPTION_STAFF',
            'COMMUNICATION_AND_CARE_PROVIDED_BY_HEALTH_PROFESSIONAL',
            'CLEANLINESS_AND_COMFORT_OF_PREMISES_AND_FACILITIES',
            'PRIVACY_AND_CONFIDENTIALITY',
            'UNDERSTANDING_OF_MY_ENTIRE_HEALTH_NEEDS'
        ]
        let dataSet = []
        AnswerArr.map(ans => {
            let ansValue = data?.reduce((accum, e) => {
                if (e.ticked === ans) accum += e.countValue
                return accum
            }, 0)
            dataSet.push(ansValue)
        })
        this.renderChart(
            {
                labels: [
                    ['Making an', 'appointment'],
                    ['Wait time at the', 'medical centre'],
                    ['Reception', 'Staff'],
                    ['Communication & Care', 'provided by health...'],
                    ['Cleanliness & comfort', 'of premises an...'],
                    ['Privacy &', 'Confidentiality'],
                    ['Understanding of', 'my entire health needs']
                ],
                datasets: [
                    {
                        label: '',
                        maxBarThickness: 25,
                        backgroundColor: this.setBackgroundColor,
                        data: dataSet,
                        fill: false,
                    },
                ],
            },
            {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: this.setLineColor
                        },
                        ticks: {
                            beginAtZero: true,
                            steps: 5,
                            stepValue: 5,
                            fontColor: this.setLabelColor,
                            padding: 5,
                            autoSkip: false,
                            maxRotation: 0,
                            minRotation: 0,
                            stepSize: 20,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.label,
                            fontColor: this.setLabelColor
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: this.setLabelColor,
                            fontSize: 10
                        },
                    }],
                },
                title: {
                    display: false,
                    text: [this.title, ''],
                    fontSize: 15,
                    fontColor: this.setLabelColor
                },
            }
        )
    },
}
</script>
