<template lang="pug">
    div(class="flex gap-4 items-center")
        v-select(
            outlined dense
            v-model="filter.surveyType"
            :items="surveysOptions"
            :attach="true"
            hide-details
            class="w-300"
            @change="filterChanged"
        )
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import DatePicker from 'vue2-datepicker'
import { format, subDays } from 'date-fns'
import { CLINIC_SUMMARY_FILTER_SURVEYS_OPTIONS, CLINIC_SUMMARY_FILTER_DATE_OPTIONS } from '@/common/constants'

export default {
    name: 'Filters',
    components: { DatePicker },
    setup(_, { emit }) {
        const surveysOptions = ref(CLINIC_SUMMARY_FILTER_SURVEYS_OPTIONS)
        const filter = ref({
            surveyType: surveysOptions.value[0]
        })

        const filterChanged = () => {
            console.log('Clinic Summary Filter: ', filter.value)
        }

        watch(() => filter.value, (newValue) => {
            console.log('Clinic Summary Filter: ', newValue)
        })

        return {
            filter,
            surveysOptions,
            filterChanged
        }
    }
}
</script>
