import {isAfter, isBefore, parse} from 'date-fns'

export const sortDates = (a, b) => {
    if (!a) return -1
    if (!b) return 1
    const dateTimeA = parse(a, 'yyyy-MM', new Date())
    const dateTimeB = parse(b, 'yyyy-MM', new Date())

    if (isBefore(dateTimeA, dateTimeB)) return -1
    else if (isAfter(dateTimeA, dateTimeB)) return 1
    else return 0
}


