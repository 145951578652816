<template lang="pug">
    .rounded.px-4.py-3.box-bg.mb-4
        .flex.items-center.justify-between.border-b-2.border-primary.pb-3
            .flex.items-center
                h1 Topic Summary
        div(class="mt-2 w-full grid lg:grid-cols-1 xl:grid-cols-2 gap-4")
            TopicList.col-span-1(
                :tenant-score="tenantScore"
                :peer-score="peerScore"
                :categories="categories"
            )
            .col-span-1
                MixedChart(
                    title="Average Survey Scores"
                    :chart-data='chartData'
                    :scales="scales"
                    :label="getTooltipText"
                    :legend="scoreLegend"
                )
</template>

<script>
import MixedChart from '@/components/charts/MixedChart'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import Filters from '@/components/dashboard/partials/Filters'
import TopicList from '@/components/dashboard/partials/TopicList'
import { useLazyQuery } from '@vue/apollo-composable'
import { getScoresByCategory, getPointRadius, getYAxisLabelWithoutDecimal } from '@/modules/surveyStats'
import { CHART_OPTION_COLORS } from '@/common/constants'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { GET_CATEGORIES_BY_FORM_SET_ID } from '@/graphql/queries/getCategories.gql'
import camelcaseKeys from 'camelcase-keys'
import { useFormSetStore } from '@/stores/useFormSetStore'
import { storeToRefs } from 'pinia'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'TopicSummary',
    components: { MixedChart, Filters, TopicList },
    setup() {
        const formSetStore = useFormSetStore()
        const { practiceSurveyStats, peerSurveyStats } = storeToRefs(formSetStore)
        const { tenantId, dashboardFilter, parentTenantId } = useGetters(['tenantId', 'dashboardFilter', 'parentTenantId'])
        const categoryParams = computed(() => {
            return {
                tenantId,
                formSetId: dashboardFilter.value.formSetId,
                parentTenantId
            }
        })

        const tenantScore = ref({})
        const peerScore = ref({})
        const categories = ref([])
        const peerData = ref({})

        const chartData = ref({
            labels: [],
            datasets: [
                {
                    label: 'Average Peer Score',
                    data: [],
                    borderColor: '#F57C00',
                    backgroundColor: '#F57C00',
                    stack: 'combined',
                    pointRadius: (data) => {
                        return getPointRadius(data)
                    },
                    pointHoverRadius: (data) => {
                        return getPointRadius(data)
                    },
                    type: 'scatter'
                },
                {
                    label: 'Average Clinic Score',
                    data: [],
                    backgroundColor: '#40C4FF',
                    stack: 'combined',
                    barThickness : 43
                },
            ]
        })
        const scales = {
            xAxes: [{
                gridLines: {
                    fontSize: 10,
                    display:false
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Average Score',
                    fontSize: 11,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans"
                },
                gridLines: {
                    display: true,
                    color: CHART_OPTION_COLORS.gridlineColor,
                    zeroLineColor: CHART_OPTION_COLORS.gridlineColor
                },
                ticks: {
                    fontSize: 10,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor,
                    fontFamily: "Open Sans",
                    beginAtZero: false,
                    stepSize: 0.5,
                    min: 0,
                    max: 5,
                    callback: getYAxisLabelWithoutDecimal
                }
            }]
        }

        const scoreLegend = {
            position: 'bottom',
            labels: {
                boxWidth: 12,
                fontColor: CHART_OPTION_COLORS.axisLabelColor,
                font: {
                    family: 'Open Sans, sans-serif',
                    size: 6
                }
            },
            reverse: true
        }

        const getTooltipText = (tooltipItem, data) => {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value} ${tooltipItem.label}`
        }

        const setDataByCategoryForChart = () => {
            const dataByCategories = []
            const scoresByCategoryKeys = Object.keys(peerData.value)

            for (let index = 0; index < categories.value.length; index++) {
                if (scoresByCategoryKeys.includes(categories.value[index])) {
                    dataByCategories[index] = peerData.value[categories.value[index]]
                } else {
                    dataByCategories[index] = 0
                }
            }

            const tempData = { ...chartData.value }

            tempData.datasets[0].data = dataByCategories
            chartData.value = { ...tempData }
            peerScore.value = { ...peerData.value }
        }

        const {
            onResult: categoriesResult,
            onError: categoriesOnError,
            refetch: categoriesRefetch,
            load: categoriesLoad,
            loading: categoriesLoading
        } = useLazyQuery(GET_CATEGORIES_BY_FORM_SET_ID, categoryParams)

        categoriesResult(({ data }) => {
            if (!data?.categories) return

            categories.value = camelcaseKeys(data.categories, {deep: true}).map(c => c.name)
            chartData.value.labels = [...categories.value]
            chartData.value = {...chartData.value}

            setDataByCategoryForChart()
        })

        watch(practiceSurveyStats, ({ data }) => {
            if (!data?.scores) return

            const dataByCategories = []
            const scoresByCategory = getScoresByCategory(data)
            const scoresByCategoryKeys = Object.keys(scoresByCategory)

            for (let index = 0; index < categories.value.length; index++) {
                if (scoresByCategoryKeys.includes(categories.value[index])) {
                    dataByCategories[index] = scoresByCategory[categories.value[index]]
                } else {
                    dataByCategories[index] = 0
                }
            }

            chartData.value.datasets[1].data = dataByCategories
            tenantScore.value = scoresByCategory
            chartData.value = {...chartData.value}
        }, { deep: true, immediate: true })

        watch(peerSurveyStats, ({ data }) => {
            if (!data?.scores) return

            peerData.value = getScoresByCategory(data)
            setDataByCategoryForChart()
        }, { deep: true, immediate: true })

        watch(() => dashboardFilter.value, (nextValue, prevValue) => {
            if (nextValue !== prevValue) categoriesRefetch()
        }, { deep: true, immediate: true })

        onMounted(() => {
            categoriesLoad()
        })

        return {
            chartData,
            scales,
            tenantScore,
            peerScore,
            categories,
            getTooltipText,
            scoreLegend
        }
    }
}
</script>
